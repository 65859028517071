import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container } from 'react-bootstrap';

const SecondPage = () => (
  <Layout>
    <SEO title="About" />
    <div class="subhead">
      <Container className="singleCol">
        <h1>About Dr. Humankind</h1>
      </Container>
    </div>

    <Container className="singleCol">
        <p>
          I am a medically board-certified pediatrician in the working world for the last 14 years. I have practiced outpatient pediatrics and currently work in a Children’s Hospital as a pediatric hospitalist for the last 11 years.
        </p>
        <p>
          What does a pediatric hospitalist do? I take care of sick kids and make them better. I am the intermediary between the ER and the pediatric intensive care unit, meaning, if the child is sick enough to be in the hospital, I take care of him/her.  However, if he or she gets too sick, I send him/her to the intensive care unit for closer monitoring or treatment. I’m a go between the ER and the PICU. My job is to identify the problem, treat the problem, and help the child go home better than when he or she came into the hospital. As I tell my own children, when I go to work I have a different set of children that I have to take care of and just like my own children at home that deserve all my attention, I have patients in the hospital who become my children and deserve all my attention when I’m there.
        </p>
        <p>
          My goal is to help the family understand what I’m doing, how I’m doing it, and why I am doing it. I also relieve a lot of stress, anxiety, and frustration. 
        </p>
        <p>
          As a working woman, we juggle, just like everyone else. I understand the mother who is a housekeeper with three or four children at home and who at times cannot go to work to take care of a sick child. I understand the mother who everyday goes to work to come home to help her children to do homework, bathe them and put them to bed. I know it’s not easy. I always say it takes a community to raise a child and, in this day and age, I truly believe we need to reclaim that sense of community.
          </p>
          <p>
            My goal with this blog and podcast is to spread kindness through the art of medicine. I want you to know that all over the world there are doctors like me that care and do their best to help this next generation. When you meet us, doctors, know that we understand your anxieties, your worries, and your frustrations. We are human and want for you what we want for ourselves and our children. We want your child to be healthy, be safe, and feel love.
            </p>
            <p>
              As I begin this journey with you, I hope I can put a little kindness in your day and give you the strength to continue through this journey called life. I am always amazed at the resilience of human beings. Therefore, be aware that if you are having a bad day or struggling with something, or need help, reach out to one another, because there are people in this world who care about you and believe in the good and kindness of humankind.
            </p>
      </Container>
  </Layout>
)

export default SecondPage
